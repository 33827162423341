import React, { useState } from 'react'
import { graphql } from "gatsby"
import { RadioGroup } from '@headlessui/react'
import useTranslations from "../hooks/useTranslations"
import {
  GiftIcon,
  ReceiptTaxIcon,
  ShieldExclamationIcon,
  ViewGridAddIcon,
} from '@heroicons/react/outline'

import LoadingAnimation from "../components/loadingAnimation"
import Accordion from '../components/accordion';
import { LocaleContext } from "../components/layout"

const voucherAmounts = [
    50,
    100,
    200
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Gutscheine(props) {
    const { locale } = React.useContext(LocaleContext)
    const { vouchersPage, loadingAnimation } = useTranslations()
    const [selectedVoucherAmount, setSelectedVoucherAmount] = useState(voucherAmounts[1])
    
    const [isActiveHowTo, setIsActiveHowTo] = useState(true);
    const [isActiveMultipleVouchers, setIsActiveMultipleVouchers] = useState(false);
    const [isActiveRedemption, setIsActiveRedemption] = useState(false);
    const [isActiveExpiration, setIsActiveExpiration] = useState(false);

    return (
        <>
            <div className="grid items-end max-w-5xl gap-6 px-4 mx-auto my-8 md:px-6 md:grid-cols-2 md:gap-8 md:pt-28 md:pb-20">
                <div className="grid h-full max-w-md gap-4 md:max-w-none">

                    <RadioGroup 
                        value={selectedVoucherAmount}
                        onChange={setSelectedVoucherAmount} 
                        className="mt-4 md:hidden">
                        <RadioGroup.Label className="sr-only">Choose a voucher value</RadioGroup.Label>
                        <div className="grid grid-flow-col gap-4">
                            {voucherAmounts.map((amount) => (
                            <RadioGroup.Option
                                key={amount}
                                value={amount}
                                checked={amount === selectedVoucherAmount}
                                className={({ active }) =>
                                classNames(
                                    active ? 'ring-2 ring-black' : '',
                                    'bg-white shadow-sm text-gray-900 cursor-pointer group relative border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1'
                                )
                                }
                            >
                                {({ active, checked }) => (
                                <>
                                    <RadioGroup.Label as="p">€{amount}</RadioGroup.Label>
                                    <div
                                        className={classNames(
                                        active ? 'border' : 'border-2',
                                        checked ? 'border-black' : 'border-transparent',
                                        'absolute -inset-px rounded-md pointer-events-none'
                                        )}
                                        aria-hidden="true"
                                    />
                                </>
                                )}
                            </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>
                    
                    <div className="grid place-items-center min-h-[265px]">
                        <div className="col-start-1 row-start-1 text-xs opacity-50 rw-loading-animation">
                            <LoadingAnimation className="w-24 h-24 mx-auto"/>
                            <div className="mt-1">{loadingAnimation.text1}</div>
                        </div>
                        <div className="col-start-1 row-start-1 duration-700 opacity-0 rw-loading-widget rw-widget-delayed">
                            <rentware-giftcard fixed-amount={selectedVoucherAmount}></rentware-giftcard>
                        </div>
                    </div>
                </div>
                <div className="">
                    <h1 className="mb-4 text-xl md:text-3xl md:mb-8">{vouchersPage.giveAwayExperiences}</h1>
                    <p>{vouchersPage.text}</p>

                    <RadioGroup 
                        value={selectedVoucherAmount} 
                        onChange={setSelectedVoucherAmount}
                        className="hidden mt-4 md:block">
                        <RadioGroup.Label className="sr-only">Choose a voucher value</RadioGroup.Label>
                        <div className="grid grid-flow-col gap-4">
                            {voucherAmounts.map((amount) => (
                            <RadioGroup.Option
                                key={amount}
                                value={amount}
                                checked={amount === selectedVoucherAmount}
                                className={({ active }) =>
                                classNames(
                                    active ? 'ring-2 ring-black' : '',
                                    'bg-white shadow-sm text-gray-900 cursor-pointer group relative border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1'
                                )
                                }
                            >
                                {({ active, checked }) => (
                                <>
                                    <RadioGroup.Label as="p">€{amount}</RadioGroup.Label>
                                    <div
                                        className={classNames(
                                        active ? 'border' : 'border-2',
                                        checked ? 'border-black' : 'border-transparent',
                                        'absolute -inset-px rounded-md pointer-events-none'
                                        )}
                                        aria-hidden="true"
                                    />
                                </>
                                )}
                            </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>
                    
                </div>
            </div>

            <div className="max-w-5xl px-4 mx-auto mb-8 delay-700 md:px-6 rw-widget-delayed">
                <div className="grid">

                    <div className="grid order-2 gap-4 mb-4 text-sm lg:order-1 md:mb-12 md:grid-cols-6 lg:grid-cols-8 lg:grid-rows-2 place-items-center">
                
                        <button
                            onClick={() => setIsActiveHowTo(!isActiveHowTo)}
                            className={classNames(
                            isActiveHowTo ? 'ring ring-als-red' : '',
                              'hover:ring-1 ring-black content-center justify-center hidden w-full h-full gap-1 p-2 bg-gray-200 lg:grid md:p-4 md:row-span-2 md:col-span-2'
                            )}
                        >
                            <GiftIcon className="w-6 h-6 mx-auto" />
                            <div className="btn btn--skewed">{vouchersPage.receiveVoucher}</div>
                        </button>
                        
                        <button
                            onClick={() => setIsActiveRedemption(!isActiveRedemption)}
                            className={classNames(
                            isActiveRedemption ? 'ring ring-als-red' : '',
                              'hover:ring-1 ring-black content-center justify-center hidden w-full h-full gap-1 p-2 bg-gray-200 lg:grid md:p-4 md:row-span-2 md:col-span-2'
                            )}
                        >
                            <ReceiptTaxIcon className="w-6 h-6 mx-auto" />
                            <div className="btn btn--skewed">{vouchersPage.redeemVoucher}</div>
                        </button>

                        <button
                            onClick={() => setIsActiveMultipleVouchers(!isActiveMultipleVouchers)}
                            className={classNames(
                            isActiveMultipleVouchers ? 'ring ring-als-red' : '',
                              'hover:ring-1 ring-black content-center justify-center hidden w-full h-full gap-1 p-2 bg-gray-200 lg:grid md:p-4 md:row-span-2 md:col-span-2'
                            )}
                        >
                            <ViewGridAddIcon className="w-6 h-6 mx-auto" />
                            <div className="btn btn--skewed">{vouchersPage.multipleVouchers}</div>
                        </button>

                        <button
                            onClick={() => setIsActiveExpiration(!isActiveExpiration)}
                            className={classNames(
                            isActiveExpiration ? 'ring ring-als-red' : '',
                              'hover:ring-1 ring-black content-center justify-center hidden w-full h-full gap-1 p-2 bg-gray-200 lg:grid md:p-4 md:row-span-2 md:col-span-2'
                            )}
                        >
                            <ShieldExclamationIcon className="w-6 h-6 mx-auto" />
                            <div className="btn btn--skewed">{vouchersPage.limitation}</div>
                        </button>

                    </div>

                    <dl className="grid mb-4 gap-y-2 md:mb-12 gap-x-8 lg:order-2">
                        {props.data.allGoogleFaqSheet.nodes.map(node => {
                            const initialOpen = (node.group === 'how-to') && isActiveHowTo && true
                            return (
                                <Accordion
                                    key={node.id}
                                    title={locale === 'de' ? node.titleDe : node.titleEn}
                                    content={locale === 'de' ? node.textDe : node.textEn}
                                    id={node.id}
                                    open={initialOpen}
                                    toggleHowTo={isActiveHowTo}
                                    toggleMultipleVouchers={isActiveMultipleVouchers}
                                    toggleRedemption={isActiveRedemption}
                                    toggleExpiration={isActiveExpiration}
                                    group={node.group}
                                />
                            )
                        })}
                    </dl>
                </div>
            </div>
        </>
    )
}

export const query = graphql`
  query Vouchers {
    allGoogleFaqSheet (filter: {site: {eq: "vouchers"}}, sort: {fields: sortId}) {
      nodes {
        id
        titleDe
        textDe
        titleEn
        textEn
        group
        site
      }
    }
  }
`