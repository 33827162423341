import React, { useState, useEffect, useRef } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid'
import ReactMarkdown from 'react-markdown'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Accordion = ({ title, content, id, open, togglePayment, group, toggleReservation, toggleWeather, toggleHowTo, toggleMultipleVouchers, toggleRedemption, toggleExpiration }) => {
  const [isActive, setIsActive] = useState(open);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    (group === 'payment') && setIsActive(togglePayment);
    (group === 'reservation') && setIsActive(toggleReservation);
    (group === 'weather') && setIsActive(toggleWeather);
    (group === 'how-to') && setIsActive(toggleHowTo);
    (group === 'multiple-vouchers') && setIsActive(toggleMultipleVouchers);
    (group === 'redemption') && setIsActive(toggleRedemption);
    (group === 'expiration') && setIsActive(toggleExpiration);
  }, [togglePayment, toggleReservation, toggleWeather, toggleHowTo, toggleMultipleVouchers, toggleRedemption, toggleExpiration, group]);

  return (
    <div>

      <dt>
        <button 
          type="button"
          aria-expanded={ isActive ? "true" : "false" }
          aria-controls={id}
          onClick={() => setIsActive(!isActive)}
          className={classNames(
            isActive ? 'text-white bg-black hover:bg-slate-800 focus:bg-slate-800' : 'text-gray-500 bg-slate-50 hover:text-gray-800',
            'flex w-full p-2 mb-2 rounded place-content-between hover:ring-1 hover:ring-black border border-slate-200'
          )}
        >
          <h3>{title}</h3>
          <ChevronDownIcon
            className={classNames(
              isActive ? 'text-gray-600 rotate-180' : 'text-gray-400',
              'ml-auto h-5 w-5 group-hover:text-gray-500'
            )}
            aria-hidden="true"
          />
        </button>
      </dt>

      <dd>
        <div id={id}
          className={classNames(
            isActive ? 'block' : 'hidden',
            'prose mb-6 md:px-2'
          )}
        >
          <ReactMarkdown>{content}</ReactMarkdown>
        </div>
      </dd>

    </div>
  );
};

export default Accordion;